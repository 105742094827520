<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-6 medium-3">
        <button @click="logOut" class="button">Log Out</button>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="small-12 medium-6 medium-offset-3">
        <h1>
          Add New Team Member
          <router-link to="/start">
            <button style="margin-top:11px" class="button float-right">View All</button>
        </router-link>
        </h1>
      </div>
    </div>
    <form v-if="addFile === false" @submit="addMember">
      <div class="grid-x grid-padding-x">
        <div class="small-12 medium-6 medium-offset-3">
          <label>Name</label>
          <input type="text" v-model="member.name" />
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="small-12 medium-6 medium-offset-3">
          <label>Title</label>
          <input type="text" v-model="member.title" />
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="small-12 medium-6 medium-offset-3">
          <label>Statement</label>
          <input type="text" v-model="member.statement" />
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="small-12 medium-6 medium-offset-3">
          <label>Signature</label>
          <input type="text" v-model="member.signature" />
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="small-12 medium-6 medium-offset-3">
          <button class="button">Next</button>
        </div>
      </div>
    </form>
    <app-add-file v-if="addFile === true" @closeUploader="fileAdded()" :memberId="member.id" />
  </div>
</template>

<script>
import AddFile from '@/components/Uploader.vue';
import Localbase from 'localbase';
import axios from '../axios';

export default {
  name: 'AddMember',
  components: {
    appAddFile: AddFile,
  },
  data() {
    return {
      member: {
        id: 0,
        name: '',
        title: '',
        statement: '',
        signature: '',
      },
      addFile: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    logOut() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.delete().then(() => {
        this.$store.commit('unsetCredentials');
        window.location.replace('/');
      });
    },
    addMember(event) {
      event.preventDefault();
      const postData = this.member;
      axios.post(`/teamMembers/addNew.json?token=${this.token}`, postData)
        .then((response) => {
          this.member = response.data;
          this.addFile = true;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
};
</script>

<style lang="scss">
.list-image {
  max-width: 75px;
}
</style>
